<template>
  <div class="hour-table-container">
    <div class="control">
      <div class="left">
        <span @click="Visible = true" class="dimension-btn"><a-icon class="dimension-icon" type="radar-chart" />维度</span>
        <div class="dimension_list sort-checkbox-group">
          <a-checkbox class="checkbox-item" @click="changeDimension(item)" v-for="item in allDimesion" :key="item.prop" :checked="query.groupByList.includes(item.key)">{{ item.label }}</a-checkbox>
        </div>
      </div>
      <div>
        <m-link-button @click="custTagsVisible = true" class="tag-btn" iconPos="right">自定义指标<TagsSvg slot="icon"/></m-link-button>
        <m-link-button style="margin-left: 10px" :disabled="exportLoading" @click="exportHourReport" iconPos="right">导出<ExportSvg slot="icon"/></m-link-button>
      </div>
    </div>
    <a-table
      class="m_table_cust"
      size="middle"
      :rowKey="(record, index) => index"
      :data-source="dataList"
      :scroll="{ x: true }"
      :columns="compColumn"
      :pagination="false"
      id="hourTable"
      ref="hourTable"
      :loading="isLoading"
    >
      <!-- 日期 -->
      <template slot="date" slot-scope="row">
        <div style="display: flex; flex-direction: column">
          <span>{{ getCaption(row.date, 0) }}</span>
          <span>{{ getCaption(row.date, 1) }}</span>
        </div>
      </template>
      <!-- 应用 -->
      <template slot="appName" slot-scope="appName, record, index">
        <div class="fixed_item" v-if="index !== 0">
          <a-popover placement="bottom" trigger="hover">
            <template #content>
              <div class="synth-popover">
                <div class="synth-popover-item">
                  <span>应用：{{ appName }}</span>
                </div>
                <div class="synth-popover-item">
                  <span>应用ID：{{ record.appId }}</span>
                  <a-button size="small" type="link" @click="copyId(record.appId)">复制</a-button>
                </div>
              </div>
            </template>
            <IosSvg v-if="record.os === 0" style="width: 14px; height: 14px" />
            <AndroidSvg v-if="record.os === 1" style="width: 14px; height: 14px" />
            {{ appName }}
          </a-popover>
        </div>
      </template>
      <!-- 广告位 -->
      <template slot="placeName" slot-scope="placeName, record, index">
        <div class="fixed_item" v-if="index !== 0">
          <a-popover placement="bottom" trigger="hover">
            <template #content>
              <div class="synth-popover">
                <div class="synth-popover-item">
                  <span>广告位ID：{{ record.placeId }}</span>
                  <a-button size="small" type="link" @click="copyId(record.placeId)">复制</a-button>
                </div>
              </div>
            </template>
            {{ placeName }}
          </a-popover>
        </div>
      </template>
      <!-- 操作系统 -->
      <template slot="os" slot-scope="os">{{ os === 1 ? '安卓' : os === 0 ? 'IOS' : '' }}</template>
      <!-- 应用包名 -->
      <div slot="bundle" slot-scope="bundle, record, index">
        <div class="fixed_item" :title="record.bundleName" v-if="index !== 0">
          {{ record.bundle || record.bundleName }}
        </div>
      </div>
      <m-tips
        v-for="item in [...sureArr]"
        :key="item.prop"
        class="hd_tips"
        :slot="item.scopedSlots.title"
        :content="item.desc"
        :title="item.label"
        :showIcon="false"
      ></m-tips>
      <template v-for="item in [...sureArr]" :slot="item.prop" slot-scope="row">
        {{ isMoney(item.prop) ? moneyText : '' }}{{ numFormat(row[item.prop], 3, '') }}{{ isRota(item.prop) ? '%' : '' }}
      </template>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 1"
      show-size-changer
      :current="query.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-if="dataList.length <= 1" style="height: 341px" imgHeight="176px">
      <template #description>暂时没有任何数据</template>
    </m-empty>
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
  </div>
</template>

<script>
import { getCpaHoursReport } from '@/apiForManage/report/cpaHours'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import excel from '@/utils/excel'
import { allDimesion, dateArr } from './list'
import request from '@/utils/request'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { copy } from '@/utils/string'
import localNameMap from '@/utils/localDataNameForManage'
import TagsSvg from '@/assets/icons/tags.svg?inline'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import mixFetchPool from '@/mixins/fetchPool'
import ExportSvg from '@/assets/icons/export.svg?inline'
import Sortable from 'sortablejs'
import CustTagsModal from '@/components/common/CustTagsModal'
import custTagObj from './localColumns'
export default {
  name: 'HoursReport',
  components: { IosSvg, AndroidSvg, TagsSvg, ExportSvg, CustTagsModal },
  mixins: [rateAndMoneyMethod, mixFetchPool],
  data () {
    return {
      custTagsVisible: false,
      defaultCheckedList: [], // 默认选中的指标
      targetArr: [], // 指标数组
      sureArr: [],
      dataList: [],
      isLoading: false,
      latitudeArr: [],
      exportLoading: false,
      allDimesion,
      // 日期 column
      dateArr,
      popover: false,
      total: 0,
      query: {
        groupByList: []
      },
      storageName: localNameMap.cpaHoursReport
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    }),
    compColumn () {
      // 日期 维度 指标
      return [...this.dateArr, ...this.latitudeArr, ...this.sureArr]
    }
  },
  mounted () {
    this.initSortable()
    this.initTargetArr()
  },
  methods: {
    // 修改自定义指标回调
    changeCustTags (targetArr, sureArr) {
      this.sureArr = sureArr
    },
    // 获取指标列表数组
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.defaultCheckedList = sureArr.map((item) => ({ ...item }))
      this.targetArr = targetArr
      if (!tagInfo) {
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
    },
    // 拖拽回调处理
    handelDimesionSort (newIndex, oldIndex) {
      const oldItem = this.allDimesion.splice(oldIndex, 1)
      this.allDimesion.splice(newIndex, 0, oldItem[0])
      this.$nextTick(() => {
        this.initSortable()
        this.changeCustDimension()
      })
    },
    // 维度拖拽排序
    initSortable () {
      const el = document.querySelector('.sort-checkbox-group')
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelDimesionSort(evt.newIndex, evt.oldIndex)
        }
      })
    },
    changeDimension (item) {
      if (this.query.groupByList.includes(item.key)) {
        this.query.groupByList = this.query.groupByList.filter((i) => i !== item.key)
      } else {
        this.query.groupByList.push(item.key)
      }
      this.changeCustDimension()
      this.query.page = 1
      this.getTableData()
    },
    numFormat,
    dealquery (query) {
      this.query = {
        ...this.query,
        ...query,
        page: 1
      }
      this.changeCustDimension()
      this.getTableData()
    },
    getCaption (obj, state) {
      var index = obj.lastIndexOf(' ')
      if (state === 0) {
        obj = obj.substring(0, index)
      } else {
        obj = obj.substring(index + 1, obj.length)
      }
      return obj
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableData()
    },
    // 选择维度处理
    changeCustDimension () {
      this.latitudeArr = this.allDimesion.filter((item) => this.query.groupByList.includes(item.key)).map((item) => ({
          key: item.key,
          title: item.label,
          dataIndex: item.prop,
          name: item.label,
          width: item.width,
          align: 'left',
          scopedSlots: { customRender: item.prop },
          ellipsis: !item.isWrap
      }))
    },
    // 获取列表
    async getTableData () {
      this.getCancelToken('hour')
      this.isLoading = true
      const { code, data = {} } = await getCpaHoursReport(this.query, this.cancelSource.token)
      if (code === 200 && data) {
        const { list = [], aggregate = {}, total = 0 } = data
        aggregate.date = '汇总'
        this.total = total
        this.dataList = list.length > 0 ? [aggregate, ...list] : []
      }
      this.isLoading = false
      this.removeCancelSource('hour')
    },
    // 导出表格接口函数
    async downloadReport (query) {
      return request.post('/report/downloadCpaHourReport', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportHourReport () {
      this.exportLoading = true
      const codesList = []
      const namesList = []
      this.compColumn.forEach((item) => {
        namesList.push(item.label)
        codesList.push(item.prop)
      })
      const parmars = {
        ...this.query,
        codeList: codesList,
        nameList: namesList
      }
      try {
        const res = await this.downloadReport(parmars)
        await excel(res, `小时报表.xls`)
        this.exportLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hour-table-container {
  border-radius: @mediumRadius;
  background: #fff;
  padding: @smallMargin;
  margin-top: @mediumMargin;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  .control {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid #eee;
    .dimension-btn{
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;
      .dimension-icon{
        color: @assisColor;
        font-size: 15px;
        margin-right: 10px;
      }
    }
    .media_popover {
      padding: 10px;
    }
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension_list {
        display: flex;
        height: 100%;
        position: relative;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          left: 0px;
          width: 1px;
          height: 22px;
          background-color: #ccc;
          transform: translateY(30%);
        }
        .checkbox-item{
          margin: 0 10px;
        }
      }
    }
    button {
      padding: 0 0 10px 0;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
  .fixed_item {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
::v-deep {
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }
}
</style>
