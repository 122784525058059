import store from '@/store'
const title = store.getters.title
export default {
  targetArr: [{
      title: `流量数据`,
      list: [

        {
          prop: 'request',
          scopedSlots: {
            customRender: 'request',
            title: 'requestTitle'
          },
          label: '流量请求',
          desc: `供应商向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
          align: 'left'
        },
        {
          prop: 'response',
          label: '流量填充',
          scopedSlots: {
            customRender: 'response',
            title: 'responseTitle'
          },
          desc: ``,
          align: 'left'
        },
        {
          prop: 'requestFilledRate',
          scopedSlots: {
            customRender: 'requestFilledRate',
            title: 'requestFilledRateTitle'
          },
          label: '流量填充率',
          desc: `供应商向${title}发送请求后，响应成功的占比`,
          align: 'left'
        }

      ]
    },
    {
      title: '预算数据',
      list: [{
          prop: 'advRequest',
          label: '广告请求',
          scopedSlots: {
            customRender: 'advRequest',
            title: 'advRequestTitle'
          },
          desc: `${title}向广告平台发送请求的次数。一次流量请求可能触发多次广告请求`,
          align: 'left'
        },
        {
          prop: 'advRequestFilledRate',
          label: '广告填充率',
          scopedSlots: {
            customRender: 'advRequestFilledRate',
            title: 'advRequestFilledRateTitle'
          },
          desc: `${title}向广告平台发送请求后，返回成功的占比`,
          align: 'left'
        }, {
          prop: 'advResponse',
          label: '广告填充',
          title: '广告填充',
          scopedSlots: {
            customRender: 'advResponse',
            title: 'advResponseTitle'
          },
          desc: `广告填充，即${title}向广告平台发送请求后返回广告填充的个数`,
          align: 'left'
        }
      ]
    }, {
      title: '展示和点击数据',
      list: [{
          prop: 'impress',
          label: '展示',
          scopedSlots: {
            customRender: 'impress',
            title: 'impressTitle'
          },
          desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
          align: 'left'
        },
        {
          prop: 'impressRate',
          label: '展示率',
          scopedSlots: {
            customRender: 'impressRate',
            title: 'impressRateTitle'
          },
          desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/流量填充)`,
          align: 'left'
        },
        {
          prop: 'click',
          label: '点击',
          scopedSlots: {
            customRender: 'click',
            title: 'clickTitle'
          },
          desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异`,
          align: 'left'
        },
        {
          prop: 'clickRate',
          label: '点击率',
          scopedSlots: {
            customRender: 'clickRate',
            title: 'clickRateTitle'
          },
          desc: `${title}统计的点击率，点击率=点击数/展示数`,
          align: 'left'
        }, {
          prop: 'impressRequestRate',
          label: '展请率',
          scopedSlots: {
            customRender: 'impressRequestRate',
            title: 'impressRequestRateTitle'
          },
          desc: ``,
          align: 'left'
        }
      ]
    },
    {
      title: '成本和收入数据',
      list: [{
        prop: 'income',
        label: '媒体消耗',
        scopedSlots: {
          customRender: 'income',
          title: 'incomeTitle'
        },
        desc: `媒体消耗=SUM(${title} Adx统计到的媒体展示成功后上报的展示价格*${title}统计到的展示次数/1000)`,
        align: 'left'
      }, {
        prop: 'advPrice',
        label: '广告收入',
        scopedSlots: {
          customRender: 'advPrice',
          title: 'advPriceTitle'
        },
        desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
        align: 'left'
      }, {
        prop: 'profitRate',
        label: '利润率',
        scopedSlots: {
          customRender: 'profitRate',
          title: 'profitRateTitle'
        },
        desc: ``,
        align: 'left'
      } ]
    },
    {
      title: '价格数据',
      list: [{
          prop: 'estimatedRevenueEcpm',
          label: '媒体成交eCPM',
          scopedSlots: {
            customRender: 'estimatedRevenueEcpm',
            title: 'estimatedRevenueEcpmTitle'
          },
          desc: `（媒体消耗/${title}统计的展示）*1000`,
          align: 'left'
        }, {
          prop: 'askPrice',
          label: '竞价响应eCPM',
          scopedSlots: {
            customRender: 'askPrice',
            title: 'askPriceTitle'
          },
          desc: ``,
          align: 'left'
        }, {
          prop: 'cpc',
          label: 'CPC',
          title: 'CPC',
          scopedSlots: {
            title: 'cpcTitle',
            customRender: 'cpc'
          },
          desc: ``,
          align: 'left'
        },

        {
          prop: 'advCpc',
          label: '广告成交CPC',
          desc: '广告收入/点击',
          scopedSlots: {
            customRender: 'advCpc',
            title: 'advCpcTitle'
          },
          align: 'left'
        }
      ]
    },
    {
      title: 'CPA数据',
      list: [{
          label: '唤端',
          prop: 'huanduan',
          scopedSlots: {
            customRender: 'huanduan',
            title: 'huanduanTitle'
          },
          align: 'left',
          desc: `广告主回调的唤醒成功数`
        },
        {
          label: '巨浪唤端',
          prop: 'julangHuanduan',
          scopedSlots: {
            customRender: 'julangHuanduan',
            title: 'julangHuanduanTitle'
          },
          align: 'left',
          desc: `广告主回调的巨浪唤醒成功数`
        },
        {
          label: '巨浪⾸唤',
          prop: 'julangShouhuan',
          scopedSlots: {
            customRender: 'julangShouhuan',
            title: 'julangShouhuanTitle'
          },
          align: 'left',
          desc: `广告主回调的巨浪首次唤醒成功数`
        },
        {
          label: '巨浪全⽹⾸唤',
          prop: 'quanwangShouhuan',
          scopedSlots: {
            customRender: 'quanwangShouhuan',
            title: 'quanwangShouhuanTitle'
          },
          align: 'left',
          desc: `广告主回调的巨浪全网首次唤醒成功数`
        },
        {
          label: '唤起率',
          prop: 'huanqiRate',
          scopedSlots: {
            customRender: 'huanqiRate',
            title: 'huanqiRateTitle'
          },
          align: 'left',
          desc: `巨浪换端/点击*100`
        },
        {
          label: '首唤率',
          prop: 'shouhuanRate',
          scopedSlots: {
            customRender: 'shouhuanRate',
            title: 'shouhuanRateTitle'
          },
          align: 'left',
          desc: `巨浪全网首唤/点击*100`
        },
        {
          label: '唤端认可率',
          prop: 'huanduanConfirmRate',
          scopedSlots: {
            customRender: 'huanduanConfirmRate',
            title: 'huanduanConfirmRateTitle'
          },
          align: 'left',
          desc: `巨浪全网首唤/巨浪首唤*100`
        },
        {
          label: 'CPA收入',
          prop: 'transformPrice',
          scopedSlots: {
            customRender: 'transformPrice',
            title: 'transformPriceTitle'
          },
          align: 'left',
          desc: `巨浪全网首唤*单价（平台配置）`
        },
        {
          label: 'CPA利润',
          prop: 'cpaProfit',
          scopedSlots: {
            customRender: 'cpaProfit',
            title: 'cpaProfitTitle'
          },
          align: 'left',
          desc: `CPA收入 - 媒体消耗`
        }
      ]
    },
    {
      title: '其他数据',
      list: [{
          label: '竞价响应率',
          prop: 'askPriceFilledRate',
          scopedSlots: {
            customRender: 'askPriceFilledRate',
            title: 'askPriceFilledRateTitle'
          },
          desc: ``,
          align: 'left'
        }, {
          prop: 'bidSuccessRate',
          label: '竞价成功率',
          scopedSlots: {
            customRender: 'bidSuccessRate',
            title: 'bidSuccessRateTitle'
          },
          desc: ``,
          align: 'left'
        },
        {
          prop: 'display',
          label: '展示占比',
          scopedSlots: {
            customRender: 'display',
            title: 'displayTitle'
          },
          desc: ``,
          align: 'left'
        },
        {
          prop: 'revenuePercentage',
          label: '媒体消耗占比',
          scopedSlots: {
            customRender: 'revenuePercentage',
            title: 'revenuePercentageTitle'
          },
          desc: ``,
          align: 'left'
        }
      ]
    }
  ],
  sureArr: [{
      prop: 'income',
      label: '媒体消耗',
      scopedSlots: {
        customRender: 'income',
        title: 'incomeTitle'
      },
      desc: `媒体消耗=SUM(${title} Adx统计到的媒体展示成功后上报的展示价格*${title}统计到的展示次数/1000)`,
      align: 'left'
    },
    {
      prop: 'estimatedRevenueEcpm',
      label: '媒体成交eCPM',
      scopedSlots: {
        customRender: 'estimatedRevenueEcpm',
        title: 'estimatedRevenueEcpmTitle'
      },
      desc: `（媒体消耗/${title}统计的展示）*1000`,
      align: 'left'
    },
    {
      prop: 'request',
      scopedSlots: {
        customRender: 'request',
        title: 'requestTitle'
      },
      label: '流量请求',
      desc: `供应商向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
      align: 'left'
    },
    {
      prop: 'requestFilledRate',
      scopedSlots: {
        customRender: 'requestFilledRate',
        title: 'requestFilledRateTitle'
      },
      label: '流量填充率',
      desc: `供应商向${title}发送请求后，响应成功的占比`,
      align: 'left'
    },
    {
      prop: 'advRequest',
      label: '广告请求',
      scopedSlots: {
        customRender: 'advRequest',
        title: 'advRequestTitle'
      },
      desc: `${title}向广告平台发送请求的次数。一次流量请求可能触发多次广告请求`,
      align: 'left'
    },
    {
      prop: 'advRequestFilledRate',
      label: '广告填充率',
      scopedSlots: {
        customRender: 'advRequestFilledRate',
        title: 'advRequestFilledRateTitle'
      },
      desc: `${title}向广告平台发送请求后，返回成功的占比`,
      align: 'left'
    },
    {
      prop: 'impress',
      label: '展示',
      scopedSlots: {
        customRender: 'impress',
        title: 'impressTitle'
      },
      desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
      align: 'left'
    },
    {
      prop: 'impressRate',
      label: '展示率',
      scopedSlots: {
        customRender: 'impressRate',
        title: 'impressRateTitle'
      },
      desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/流量填充)`,
      align: 'left'
    },
    {
      prop: 'click',
      label: '点击',
      scopedSlots: {
        customRender: 'click',
        title: 'clickTitle'
      },
      desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异`,
      align: 'left'
    },
    {
      prop: 'clickRate',
      label: '点击率',
      scopedSlots: {
        customRender: 'clickRate',
        title: 'clickRateTitle'
      },
      desc: `${title}统计的点击率，点击率=点击数/展示数`,
      align: 'left'
    },
    {
      prop: 'advPrice',
      label: '广告收入',
      scopedSlots: {
        customRender: 'advPrice',
        title: 'advPriceTitle'
      },
      desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
      align: 'left'
    }
  ]
}
